import {format as dateFormat, differenceInDays} from "date-fns"
import {agnosticSubDays as subDays, agnosticAddDays as addDays} from "./helpers.js"
import shuffle_seed from "shuffle-seed"

function get_days_random_words(today, adjectives, nouns, day_zero) {
	let days_since = differenceInDays(today, day_zero)
	let adjectives_seed = +subDays(today, days_since%adjectives.length)
	let nouns_seed = +subDays(today, days_since%nouns.length)*2
	return [shuffle_seed.shuffle(adjectives, adjectives_seed)[days_since%adjectives.length].toLowerCase(), 
			shuffle_seed.shuffle(nouns, nouns_seed)[days_since%nouns.length].toLowerCase()]
}
export default get_days_random_words