import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals.js';
import {format as dateFormat} from "date-fns"
import {agnosticSubDays as subDays, agnosticAddDays as addDays} from "./lib/helpers.js"
import {utcToZonedTime} from "date-fns-tz"
import "./fontello/css/icons.css"

import world from "./config/world.json"

const root = ReactDOM.createRoot(document.getElementById('root'));
const today = new Date(dateFormat(new Date(), world.date_format))
let forceReload = false
const classes = []
const ua = window.navigator.userAgent;
const iOS = ua.match(/iPad/i) || ua.match(/iPhone/i);
if (iOS) {
  classes.push("ios")
}
const webkit = ua.match(/WebKit/i);
if (webkit) {
  classes.push("webkit")
}
const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
if (iOSSafari) {
  classes.push("iossafari")
}

root.render(
  <React.StrictMode>
    <App today={today} forceReload={forceReload} rootClasses={classes}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
