import './puzzle.css';
import Input from "../input/input.jsx"
import FakeInput from "../input/fake_input.jsx"
import {unzip as _unzip} from "lodash"

function Puzzle({adjective, noun, guesses, overrides, override_states, cursorLocation, onClickInput, last_solve, dont_shake}) {
  let shake = false
  for (let [key, override] of Object.entries(overrides)) {
    if (override.state === override_states.incorrect) {
      shake = true
    }
  }
  if (dont_shake) {
    shake = false
  }
  let noun_offset = adjective.length
  let [adjective_inputs, adjective_fake_inputs] = _unzip(Array.from(adjective).map((letter, index)=>{
    function _onClick(event) {
      if (onClickInput) {
        return onClickInput({index, letter, event})
      }
    }
    let override = overrides[index] || null
    let selected = cursorLocation === index
    let fake_input = (<span/>)
    if (last_solve) {
      fake_input = (<FakeInput correct_letter={letter} letter={last_solve[index]}/>)
    }
    let input = (<Input shake={shake} letter={letter} override={override} override_states={override_states} guesses={guesses} selected={selected} key={index} onClick={_onClick}/>)
    return [input,fake_input]
  }))
  let [noun_inputs, noun_fake_inputs] = _unzip(Array.from(noun).map((letter, index)=>{
    function _onClick(event) {
      if (onClickInput) {
        return onClickInput({index: index+noun_offset, letter})
      }
    }
    let override = overrides[index+noun_offset] || null
    let selected = cursorLocation === index+noun_offset
    let fake_input = (<span/>)
    if (last_solve) {
      fake_input = (<FakeInput correct_letter={letter} letter={last_solve[index+noun_offset]}/>)
    }
    let input = (<Input shake={shake} letter={letter} override={override} override_states={override_states} guesses={guesses} selected={selected} key={index} onClick={_onClick}/>)
    return [input,fake_input]
  }))
  return (
    <div className="Puzzle" data-inputs={adjective.length+noun.length}>
      <div className="Puzzle__adjective">
        <div className="Puzzle__adjective__header">Adjective</div>
        {/*<div className="Puzzle__adjective__fake-inputs">
          {adjective_fake_inputs}
        </div>*/}
        <div className="Puzzle__adjective__inputs">
          {adjective_inputs}
        </div>
      </div>
      <div className="Puzzle__noun">
        <div className="Puzzle__noun__header">Noun</div>
        {/*<div className="Puzzle__adjective__fake-inputs">
          {noun_fake_inputs}
        </div>*/}
        <div className="Puzzle__noun__inputs">
          {noun_inputs}
        </div>
      </div>
    </div>
  );
}

export default Puzzle;
