
function StatsBar({statname, active, value, max}) {

  	return (
    	<div className={"statsbar"}>
    		<div className="statsbar__statname">{statname}:</div>
    		<div className="statsbar__bar progress-bar">
    			<span className={"bar " + (active ? "active" : "")} style={{width: value == 0 ? "0%" : Math.round((value/max)*100) + "%"}}><span className="text">{value}</span></span>
    		</div>
    	</div>
  	);
}

export default StatsBar;
