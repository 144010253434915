import './input.css';

function FakeInput({letter, correct_letter}) {
  let text_for_show = letter
  let cls="FakeInput"
  if (letter === correct_letter) {
    cls+=" locked"
  } else {
    cls += " incorrect"
  }
  return (
    <div className={cls}>
      <div className="Input__letter">{text_for_show}</div>
    </div>
  )  
}

export default FakeInput;
