import {subDays, addDays, subMinutes, addMinutes} from "date-fns"
function agnosticAddDays(date, amount) {
    const originalTZO = date.getTimezoneOffset()
    const endDate = addDays(date, amount)
    const endTZO = endDate.getTimezoneOffset()

    const dstDiff = originalTZO - endTZO

    return dstDiff >= 0
        ? addMinutes(endDate, dstDiff)
        : subMinutes(endDate, Math.abs(dstDiff))
}

function agnosticSubDays(date, amount) {
    return agnosticAddDays(date, -amount)
}

export {agnosticAddDays, agnosticSubDays}