import './input.css';

function Input({letter, override, guesses, selected, override_states, shake, onClick}) {
  let text_for_show = " "
  let guessed = guesses.indexOf(letter) >= 0
  if (override) {
    text_for_show = override.letter
    guessed |= override.state === override_states.correct
  }
  if (guessed) {
    text_for_show = letter
  }
  function _onClick(event) {
    if (onClick) {
      onClick({event, letter})
    }
  }
  let cls="Input"
  if (selected) {
    cls+=" selected"
  }
  if (guessed) {
    cls+=" locked"
  } 
  if (override && override.state == override_states.incorrect) {
    cls += " incorrect"
  }
  if (shake) {
    cls += " shake"
  }
  return (
    <div className={cls} onClick={_onClick}>
      <div className="Input__letter">{text_for_show}</div>
    </div>
  )  
}

export default Input;
