import './about_screen.css';
import world from "../config/world.json"

function AboutScreen({show, onClose}) {
	let cls = "AboutScreen"
	if (!show) {
		cls += " hidden"
	}
	function _onCloseClick(event) {
		if (onClose) {
			onClose(event)
		}
	}
  	return (
    	<div className={cls}>
    		<div className="coverall" onClick={_onCloseClick}/>
    		<div className="AboutModal">
    			<div className="AboutModal__close" onClick={_onCloseClick}></div>
    				<div className="AboutModal__header"><div className="header-logo"/><span>{world.app_name}</span></div>
	    			<div className="AboutModal__body">
	    				<div className="AboutModal__faq-section">
		    				<div className="AboutModal__faq-section__header">What is Magic AI Puzzle?</div>
		    				<div className="AboutModal__faq-section__text">Each day, a phrase consisting of an adjective and a noun is used to generate a daily puzzle image, which is drawn by an artificial intelligence (currently we use <a href={world.ai_link}>{world.ai_name}</a>, thanks, guys!).<br/><br/>Ever wondered what a "clumsy squirrel" looks like? Our AI friend may just show you!</div>
		    			</div>
		    			<div className="AboutModal__faq-section">
		    				<div className="AboutModal__faq-section__header">How do I play?</div>
		    				<div className="AboutModal__faq-section__text">Guess the adjective and noun which the magic AI has drawn for the day.
		    					<ul className="AboutModal__faq-section__text__bullets">
		    						<li>Each solution will be a valid adjective and a noun</li>
		    						<li>You can make a one letter guess by selecting a letter on the keyboard</li>
		    							<div className="how-to-play__or">or</div>
		    						<li>You can try to solve the entire puzzle by selecting the fillable boxes, typing your complete guess, and pressing solve</li>
		    						<li>You have {world.max_guesses} guesses to solve the puzzle. Either guessing a letter or trying to solve the puzzle will use up one guess. After you run out of guesses, you will have a final attempt to solve the entire puzzle</li>
		    						<li>Try to solve the puzzle in as few guesses as you can!</li>
		    					</ul>
		    				</div>
		    			</div>
		    			<div className="AboutModal__faq-section">
		    				<div className="AboutModal__faq-section__header">How is that pronounced?</div>
		    				<div className="AboutModal__faq-section__text">Magic "Eye" Puzzle. It's a pun.</div>
		    			</div>
	    			</div>
    		</div>
    	</div>
  	);
}

export default AboutScreen;
