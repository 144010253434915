function Key({letter, key_state, onClick}) {
	function _onClick(event) {
		onClick({"key": letter, "event": event})
	}
	let cls = "Keyboard__Row__Key"
	if (key_state == Key.states.correct) {
		cls += " correct"
	}
	if (key_state == Key.states.incorrect) {
		cls += " incorrect"
	}
	if (key_state == Key.states.disabled) {
		cls += " disabled"
	}
	if (letter === "solve") {
		cls += " solver"
	}
	
  	return (
    	<div className={cls} disabled={key_state!=Key.states.active} onClick={_onClick}>
    		<div className="Keyboard__Row__Key__letter">{letter}</div>
    	</div>
  	);
}

Key.states = {
	"active": 0,
	"correct": 1,
	"incorrect": 2,
	"disabled": 3
}

export default Key;
