import './statistics_screen.css';
import StatsBar from "./stats_bar.jsx"
import world from "../config/world.json"

function StatisticsScreen({statistics, show, guess_count, failed, won, onClose, onShareGameClick, show_install_app, onShareStatsClick, onPeekTomorrowClick, onInstallAppClick}) {
	let cls = "StatisticsScreen"
	if (!show) {
		cls += " hidden"
	}
	let bars = []
	let max_wins = 0
	for (let i = 0; i <= world.max_guesses; i++)
	{
		max_wins = Math.max(statistics[i], max_wins)
	}
	for (let i = 0; i <= world.max_guesses; i++)
	{
		bars.push((
			<StatsBar active={guess_count==i && won} statname={i} value={statistics[i]} max={max_wins} key={i}/>
		))
	}
	function _onCloseClick(event) {
		if (onClose) {
			onClose(event)
		}
	}
	function _onShareGameClick(event) {
		if (onShareGameClick) {
			onShareGameClick(event)
		}
	}
	function _onShareStatsClick(event) {
		if (onShareStatsClick) {
			onShareStatsClick(event)
		}
	}
	function _onPeekTomorrowClick(event) {
		if (onPeekTomorrowClick) {
			onPeekTomorrowClick(event)
		}
	}
	function _onInstallAppClick(event) {
		if (onInstallAppClick) {
			onInstallAppClick(event)
		}
	}
  	return (
    	<div className={cls}>
    		<div className="coverall" onClick={_onCloseClick}/>
    		<div className="StatisticsModal">
    			<div className="StatisticsModal__close" onClick={_onCloseClick}></div>
    			<div className="StatisticsModal__screenshot-div">
    				<div className="StatisticsModal__header"><div className="header-logo"/><span>{world.app_name}</span></div>
	    			<div className="StatisticsModal__statistics-bar-holder">
	    				<div className="StatisticsModal__statistics-bar-holder__header">
	    				</div>
	    				{bars}
	    			</div>
	    			<div className="StatisticsModal__stat-holder">
		    			<div className="StatisticsModal__stat StatisticsModal__stat--attempts"><div className="StatisticsModal__stat__holder"><span className="StatisticsModal__stat__title">Played</span><span className="StatisticsModal__stat__value">{statistics.loss+statistics.won || 0}</span></div></div>
		    			<div className="StatisticsModal__stat StatisticsModal__stat--win-percent"><div className="StatisticsModal__stat__holder"><span className="StatisticsModal__stat__title">Win %</span><span className="StatisticsModal__stat__value">{Math.round((statistics.won/(statistics.loss+statistics.won))*100)||0}%</span></div></div>
		    			<div className="StatisticsModal__stat StatisticsModal__stat--streak"><div className="StatisticsModal__stat__holder"><span className="StatisticsModal__stat__title">Streak</span> <span className="StatisticsModal__stat__value">{statistics.streak}</span></div></div>
		    			<div className="StatisticsModal__stat StatisticsModal__stat--max-streak"><div className="StatisticsModal__stat__holder"><span className="StatisticsModal__stat__title">Max Streak</span><span className="StatisticsModal__stat__value">{statistics.max_streak}</span></div></div>
	    			</div>

		    	</div>
    			<div className="StatisticsModal__footer">
    				{(won || failed) ? <button className="share-button--game share-button button" onClick={_onShareGameClick}><span className="share_game icon-share"></span><span>Share Last Game</span></button> : ""}
    				{(won || failed) ? <button className="share-button--peek share-button button" onClick={_onPeekTomorrowClick}><span className="peek_tomorrow icon-eye"></span><span>Peek Tomorrow</span></button> : ""}
    				{show_install_app ? <button className="install-app-button share-button button" onClick={_onInstallAppClick}><span className="install_app icon-install"></span><span>Install App</span></button> : ""}
    			</div>
    		</div>
    	</div>
  	);
}

export default StatisticsScreen;
