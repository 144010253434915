import Key from "./key.jsx"

function Row({letters, state_map, onKeyPressed}) {
	function _onClick({key}) {
		if (onKeyPressed) {
			onKeyPressed({key})
		}
	}
	let keys = letters.map((letter, index)=>{
		return (<Key letter={letter} onClick={_onClick} key_state={state_map[letter] || 0} key={index}></Key>)
	})
  	return (
    	<div className="Keyboard__Row">
    		{keys}
    	</div>
  	);
}

export default Row;
