import './data_modal.css';
import world from "../config/world.json"

function DataModal({show, children, title, onClose}) {
	let cls = "DataModalHolder"
	if (!show) {
		cls += " hidden"
	}
	function _onCloseClick(event) {
		if (onClose) {
			onClose(event)
		}
	}
  	return (
    	<div className={cls}>
    		<div className="coverall"  onClick={_onCloseClick}/>
    		<div className="DataModal">
    			<div className="DataModal__close" onClick={_onCloseClick}></div>
    			<div className="DataModal__header">{title}</div>
    			<div className="DataModal__holder">{children}</div>
    		</div>
    	</div>
  	);
}

export default DataModal;
