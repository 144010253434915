import './App.css';
import './animations.css';
import {useState, useEffect} from "react"
import words from "./config/words.json"
import world from "./config/world.json"
import {format as dateFormat, parseJSON, isEqual} from "date-fns"
import {agnosticSubDays as subDays, agnosticAddDays as addDays} from "./lib/helpers.js"
import {utcToZonedTime} from "date-fns-tz"
import get_days_random_words from "./lib/get_days_random_words.js"
import Puzzle from "./puzzle/puzzle.jsx"
import Keyboard from "./keyboard/keyboard.jsx"
import StatisticsScreen from "./statistics_screen/statistics_screen.jsx"
import AboutScreen from "./about_screen/about_screen.jsx"
import DataModal from "./data_modal/data_modal.jsx"
import {isEqual as _isEqual, cloneDeep as _cloneDeep, map as _map, forEach as _forEach} from "lodash"
import copy_to_clipboard from 'copy-to-clipboard'
import {Toaster, toast} from "react-hot-toast"
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import logo from "./static/logo512.png";

function store_local_value(key, value, setter) {
  localStorage.setItem(key, JSON.stringify(value))
  setter(value)
}

function get_local_value(key) {
  try {
    return JSON.parse(localStorage.getItem(key))  
  } catch {
    return null
  }
}

const DEFAULT_STATISTICS = {
  "loss": 0,
  "won": 0,
  "streak": 0,
  "max_streak": 0
}
for (let i = 0; i<=world.max_guesses; i++) {
  DEFAULT_STATISTICS[i] = 0
}
let loaded = false;
const INVALID_CLICK_CLASSES = new Set(
  ["App-body",
    "title-text",
    "art",
    "Keyboard",
    "Puzzle",
    "hook-text"]
)

const BOARD_STATES = {
  "empty": 0,
  "correct": 1,
  "overridden": 2,
  "incorrect": 3
}

const BOARD_STATE_TEXT_MAP = {

}

BOARD_STATE_TEXT_MAP[BOARD_STATES.empty] = "⬛"
BOARD_STATE_TEXT_MAP[BOARD_STATES.correct] = "🟩"
BOARD_STATE_TEXT_MAP[BOARD_STATES.incorrect] = "🟥"


const LETTERS = new Set(["q","w","e","r","t","y","u","i","o","p",
        "a","s","d","f","g","h","j","k","l",
        "z","x","c","v","b","n","m"])

function App({today, forceReload, rootClasses}) {
  let old_today
  try {
    old_today = parseJSON(get_local_value("today"))
  } catch {
    old_today = subDays(new Date(world.day_zero), 1)
  }

  const [guesses, setGuesses] = useState(get_local_value("guesses") || [])
  const [adjective, setAdjective] = useState(get_local_value("adjective"))
  const [noun, setNoun] = useState(get_local_value("noun"))
  const [inputState, setInputState] = useState(false)
  const [inputOverrides, setInputOverrides] = useState(get_local_value("input_overrides") || {})
  const [tempCursorLocation, setTempCursorLocation] = useState(-1)
  const [failed, setFailed] = useState(get_local_value("failed") || false)
  const [won, setWon] = useState(get_local_value("won") || false)
  const [lastSolve, setLastSolve] = useState(get_local_value("last_solve"))
  const [cursorLocation, setCursorLocation] = useState(-1)
  const [canSolve, setCanSolve] = useState(false)
  const [statistics, setStatistics] = useState(get_local_value("statistics"))
  const [showStatistics, setShowStatistics] = useState(false)
  const [showPeek, setShowPeek] = useState(false)
  const [showAbout, setShowAbout] = useState(false)
  const [showShareTypeDialog, setShowShareTypeDialog] = useState(false)
  const [showInstallPrompt, setShowInstallPrompt] = useState(false)
  const [doneOnce, setDoneOnce] = useState(false)
  const [justEnded, setJustEnded] = useState(false)
  const [winOnLoad, setWinOnLoad] = useState(!doneOnce&&(won||failed))
  const [aboutDismissed, setAboutDismissed] = useState(get_local_value("about_dismissed") || false)
  const [disableInteraction, setDisableInteraction] = useState(false)
  const [annoyedToInstall, setAnnoyedToInstall] = useState(get_local_value("annoyed_to_install") || null)
  const [cachedInstallPrompt, setCachedInstallPrompt] = useState(null)
  const about_updated = new Date(world.about_updated)
  useEffect(()=>{
    for (let classname of rootClasses) {
      document.querySelector(':root').classList.add(classname)
    }
  }, [])
  if (statistics == null || !_isEqual(new Set(Object.keys(statistics)), new Set(Object.keys(DEFAULT_STATISTICS)))) {
    store_local_value("statistics", DEFAULT_STATISTICS, setStatistics)
  }
  if ((!aboutDismissed || aboutDismissed < about_updated) && !doneOnce) {
    setShowAbout(true)
  }
  if ((won || failed) && !doneOnce) {
    setShowStatistics(true)
    if ((statistics.won + statistics.loss >= 3) && !annoyedToInstall) {
      setTimeout(()=>{
        setShowInstallPrompt(true)
      }, 1000)
    }
  }
  if (justEnded) {
    setTimeout(()=>{
      setJustEnded(false)
    }, 500)
  }
  const today_string = dateFormat(utcToZonedTime(today), world.date_format)
  const tomorrow_string = dateFormat(utcToZonedTime(addDays(today,1)), world.date_format)
  const adjectivenoun = adjective+noun
  function reset_game(won) {
    const day_zero = new Date(world.day_zero)
    const [adjective, noun] = get_days_random_words(today, words["adjectives"], words["nouns"], day_zero)
    let new_statistics = get_local_value("statistics")
    store_local_value("guesses", [], setGuesses)
    store_local_value("adjective", adjective, setAdjective)
    store_local_value("noun", noun, setNoun)
    if ((!won || !isEqual(subDays(today, 1), old_today)) && !forceReload) {
      new_statistics["streak"] = 0
    }
    store_local_value("statistics", new_statistics, setStatistics)
    store_local_value("won", false, setWon)
    store_local_value("failed", false, setFailed)
    store_local_value("today", today, ()=>{})
    store_local_value("input_overrides", {}, setInputOverrides)
  }

  if (!isEqual(old_today, today) || (forceReload && !doneOnce)) {
    reset_game(get_local_value("won"))
  }

  let state_map = {}
  state_map = Object.fromEntries(guesses.map(letter=>{
    let in_words = (adjective.indexOf(letter)>=0 || noun.indexOf(letter)>=0)
    return [letter, in_words ? Keyboard.Key.states.correct : Keyboard.Key.states.incorrect ]
  }))
  if (!inputState || Object.keys(inputOverrides).length === 0) {
    state_map["<"] = Keyboard.Key.states.disabled
  }
  if (!canSolve) {
    state_map["solve"] = Keyboard.Key.states.disabled
  }
  function onInputClick({index, letter, event}) {
    if (won || failed) {
      return
    }
    if (guesses.indexOf(letter) < 0 && (!inputOverrides[index] || inputOverrides[index].state !== BOARD_STATES.correct)) {
      setInputState(true)
      setCursorLocation(index)
      setTempCursorLocation(index)
    }
  }
  function get_gameboard(adjective, noun, guesses, overrides) {
    let board = new Array(adjective.length+noun.length)
    let adjectivenoun = adjective+noun
    for (let i=0; i<adjective.length+noun.length; i++) {
      if (guesses.indexOf(adjectivenoun[i])>=0 || (overrides[i] && overrides[i].state===BOARD_STATES.correct)) {
        board[i] = {"state": BOARD_STATES.correct, "letter": adjectivenoun[i], index:i}
      } else if (overrides.hasOwnProperty(i)) {
        board[i] = {"state": BOARD_STATES.overridden, "letter": overrides[i].letter, index:i}
      } else {
        board[i] = {"state": BOARD_STATES.empty, "letter": "", index:i}
      }
    }
    return board
  }
  function is_board_full(board) {
    for (let {state, letter} of board) {
      if (state === BOARD_STATES.empty) {
        return false
      }
    }
    return true
  }
  function is_board_correct(board, adjective, noun) {
    let adjectivenoun = adjective+noun
    for (let {state, letter, index} of board) {
      if (letter !== adjectivenoun[index]) {
        return false
      }
    }
    return true
  }
  function get_board_text(board) {
    return board.map(({letter})=>letter).join("")
  }
  function win_game(guess) {
    let new_statistics = get_local_value("statistics")
    store_local_value("won", true, setWon)
    store_local_value("failed", false, setFailed)
    setJustEnded(true)
    store_local_value("last_solve", guess, setLastSolve)

    new_statistics[get_local_value("guesses").length]++
    new_statistics["won"]++
    new_statistics["streak"]++
    new_statistics["max_streak"] = Math.max(new_statistics["max_streak"], new_statistics["streak"])
    store_local_value("statistics", new_statistics, setStatistics)
    setTimeout(()=>{
      setShowStatistics(true)
      if ((statistics.won + statistics.loss >= 3) && !annoyedToInstall) {
        setTimeout(()=>{
          setShowInstallPrompt(true)
          store_local_value("annoyed_to_install", new Date(), setAnnoyedToInstall)
        }, 1000)
      }
    }, 1500)
  }
  function lose_game(guess) {
    let new_statistics = get_local_value("statistics")
    store_local_value("won", false, setWon)
    store_local_value("failed", true, setFailed)
    setJustEnded(true)
    store_local_value("last_solve", guess, setLastSolve)

    new_statistics["loss"]++
    new_statistics["streak"] = 0
    store_local_value("statistics", new_statistics, setStatistics)
    setTimeout(()=>{
      setShowStatistics(true)
      if ((statistics.won + statistics.loss >= 3) && !annoyedToInstall) {
        setTimeout(()=>{
          setShowInstallPrompt(true)
        }, 1000)
      }
    }, 1500)
  }
  function onKeyboardKeyPressed({key}) {
    if (won || failed || disableInteraction) {
      return
    }
    let adjectivenoun = adjective+noun
    if (key == "<") {
      if (inputState) {
        let board = get_gameboard(adjective, noun, guesses, inputOverrides)
        let new_cursor_location = cursorLocation-1
        if (cursorLocation === -1) {
          new_cursor_location = (adjective.length+noun.length-1)
        }
        while (new_cursor_location >= 0 && board[new_cursor_location].state === BOARD_STATES.correct) {
          new_cursor_location--
        }
        if (new_cursor_location < 0) {
          return
        }
        let {[new_cursor_location]:_, ...new_overrides} = inputOverrides
        store_local_value("input_overrides", new_overrides, setInputOverrides)
        setCursorLocation(new_cursor_location)
        setTempCursorLocation(new_cursor_location)
        setCanSolve(false)
      }
    } else if (key === "solve") {
      if (canSolve) {
        let board = get_gameboard(adjective, noun, guesses, inputOverrides)
        let guess = get_board_text(board)
        if (is_board_correct(board, adjective, noun)) {
          win_game(guess)
        } else {
          let new_overrides = {}
          for (let v of board) {
            if (v.letter === adjectivenoun[v.index]) {
              new_overrides[v.index] = {letter: v.letter, state: BOARD_STATES.correct}
            } else {
              new_overrides[v.index] = {letter: v.letter, state: BOARD_STATES.incorrect}
            }
          }
          setDisableInteraction(true)
          store_local_value("input_overrides", new_overrides, setInputOverrides)
          let g = get_local_value("guesses")
          if (g.length >= world.max_guesses) {
            lose_game(guess)
          } else {
            g.push(guess)
            store_local_value("guesses", g, setGuesses)
          }
          setTimeout(()=>{
            setDisableInteraction(false)
            let newer_overrides = {}
            for (let v of board) {
              if (new_overrides[v.index].state === BOARD_STATES.correct) {
                newer_overrides[v.index] = new_overrides[v.index]
              }
            }
            store_local_value("input_overrides", newer_overrides, setInputOverrides)
            setDisableInteraction(false)
          }, 1000)
        }
        setCanSolve(false)
        setInputState(false)
      }
    }
    else if (inputState) {
      let board = get_gameboard(adjective, noun, guesses, inputOverrides)
      if (cursorLocation != tempCursorLocation && tempCursorLocation !== -1) {
        if (key === board[tempCursorLocation].letter) {
          setTempCursorLocation(tempCursorLocation+1)
          return
        }
      }
      let new_overrides = {}
      let incorrect = (guesses.indexOf(key)>=0)
      for (let guess of guesses) {
        if (guess.length > 1) {
          if (guess[cursorLocation] === key && !(adjectivenoun[cursorLocation] === key)) {
            incorrect = true
          }
        }
      }
      new_overrides[cursorLocation] = {"letter": key, "state": incorrect ? BOARD_STATES.incorrect : BOARD_STATES.overridden}
      new_overrides = Object.assign({}, inputOverrides, new_overrides)
      store_local_value("input_overrides", new_overrides, setInputOverrides)
      board = get_gameboard(adjective, noun, guesses, new_overrides)
      let known_wrong = false
      for (let override of Object.values(new_overrides)) {
        if (override.state === BOARD_STATES.incorrect) {
          known_wrong = true
        }
      }
      if (is_board_full(board) && !known_wrong) {
        setCanSolve(true)
        setCursorLocation(-1)
        setTempCursorLocation(-1)
      } else {
        let new_cursor_location = (cursorLocation+1)
        setTempCursorLocation(new_cursor_location)
        while ((new_cursor_location < (adjectivenoun.length)) && board[new_cursor_location].state !== BOARD_STATES.empty) {
          new_cursor_location = (new_cursor_location+1)
        }
        if (new_cursor_location >= adjectivenoun.length) {
          new_cursor_location = -1
        }
        setCursorLocation(new_cursor_location)
      }
    }
    else if (guesses.length >= world.max_guesses) {
      
    } 
    else {
      if (guesses.indexOf(key) < 0) {
        let g = get_local_value("guesses")
        g.push(key)
        store_local_value("guesses", g, setGuesses)
        let board = get_gameboard(adjective, noun, g, inputOverrides)
        if (is_board_correct(board, adjective, noun)) {
          win_game(adjectivenoun)
        }
      }
    }
  }
  function onAppClick(event) {
    if (won || failed || disableInteraction){
      return
    }
    if (INVALID_CLICK_CLASSES.has(event.target.getAttribute("class"))) {
      setInputState(false)
      setCursorLocation(-1)
      let cleared_input_overrides = {}
      for (let [index, value] of Object.entries(inputOverrides)) {
        if (value.state === BOARD_STATES.correct) {
          cleared_input_overrides[index] = value
        }
      }
      store_local_value("input_overrides", cleared_input_overrides, setInputOverrides)
      setCanSolve(false)
    }
  }
  function onKeyUp(event) {
    if (won || failed || disableInteraction) {
      return
    }
    if (event.ctrlKey || event.metaKey) {
      return
    }
    if (LETTERS.has(event.key.toLowerCase())) {
      onKeyboardKeyPressed({key: event.key.toLowerCase()})
    } else if (event.key === "Backspace") {
      onKeyboardKeyPressed({key: "<"})
    } else if (event.key === "Enter") {
      onKeyboardKeyPressed({key: "solve"})
    }
  }
  let guesses_text = world.max_guesses
  guesses_text = world.max_guesses-guesses.length
  if (guesses_text <= 0) {
    guesses_text = "No more letters! Solve the puzzle!"
  }

  useEffect(() => {
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyUp]);
  useEffect(()=>{
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      setCachedInstallPrompt(e);
    });
  })

  let overrides = inputOverrides
  if (won || failed) {
    let new_overrides = {}
    for (let [index,letter] of Object.entries(lastSolve)) {
      if (letter === adjectivenoun[index]) {
        new_overrides[index] = {letter: letter, state: BOARD_STATES.correct}
      } else{
        new_overrides[index] = {letter: letter, state: BOARD_STATES.incorrect}
      }
    }
    overrides = new_overrides
  }
  function get_game_script(guesses, last_solve, adjective, noun) {
    function is_complete(state) {
      for (let v of state["adjective"].concat(state["noun"])) {
        if (v !== BOARD_STATES.correct) {
          return false
        }
      }
      return true
    }
    if (last_solve){
      guesses = guesses.concat([last_solve])
    }
    let states = []
    let state = {"adjective": Array.from(adjective).map(()=>BOARD_STATES.empty), "noun": Array.from(noun).map(()=>BOARD_STATES.empty)}
    let adjectivenoun = adjective+noun
    for (let guess of guesses) {
      let this_state = _cloneDeep(state)
      if (guess.length === 1) {
        _forEach(adjective, (letter, index)=>{
          if (letter === guess) {
            this_state["adjective"][index] = BOARD_STATES.correct
          } else {
            this_state["adjective"][index] = (this_state["adjective"][index] !== BOARD_STATES.correct) ? BOARD_STATES.empty : BOARD_STATES.correct
          }
        })
        _forEach(noun, (letter, index)=>{
          if (letter === guess) {
            this_state["noun"][index] = BOARD_STATES.correct
          } else {
            this_state["noun"][index] =  (this_state["noun"][index] !== BOARD_STATES.correct) ? BOARD_STATES.empty : BOARD_STATES.correct
          }
        })
      } else {
        _forEach(guess, (letter, index)=>{
          let state_to_set = this_state["adjective"]
          let sub_index = index
          if (index >= adjective.length) {
            state_to_set = this_state["noun"]
            sub_index = index - adjective.length
          }
          if (letter === adjectivenoun[index]) {
            state_to_set[sub_index] = BOARD_STATES.correct
          } else {
            state_to_set[sub_index] = BOARD_STATES.incorrect
          }
        })
      }
      states.push(this_state)
      state = this_state
      if (is_complete(state)) {
        break
      }
    }
    return states
  }
  function game_script_to_string(script) {
    let state_strings = [`${world.app_name}\n${today_string}`]
    for (let state of script) {
      let state_string = ""
      state_string += state['adjective'].map((state)=>{
        return BOARD_STATE_TEXT_MAP[state]
      }).join("")
      state_string += " "
      state_string += state['noun'].map((state)=>{
        return BOARD_STATE_TEXT_MAP[state]
      }).join("")
      state_strings.push(state_string)
    }
    return state_strings.join("\n")
  }
  function game_stats_to_string(stats) {
    let stats_string = `${world.app_name} Statistics:\n`
    for (let i=0; i<=world.max_guesses;i++) {
      stats_string += `${i}: ${stats[i]}\n`
    }
    stats_string+=`Played: ${statistics.loss+statistics.won || 0}\n`
    stats_string+=`Win %: ${Math.round((statistics.won/(statistics.loss+statistics.won))*100)||0}%\n`
    stats_string+=`Streak: ${statistics.streak}\n`
    stats_string+=`Max Streak: ${statistics.max_streak}\n`
    return stats_string
  }
  async function load_image(url) {
    let img = new window.Image();
    let p = new Promise(r=>{img.onload=r})
    img.setAttribute("src", url)
    await p
    return img
  }
  async function game_stats_to_image(stats) {
    return await domtoimage.toPng(document.getElementsByClassName("StatisticsModal__screenshot-div")[0])
  }
  async function game_script_to_image(script) {
    let canvas = document.createElement('canvas');
    const title_height = 25
    const title_padding = 10
    const date_height = 10
    const date_padding = 10
    const image_height = 200
    const image_width = image_height
    const ai_image_scale = .7
    const box_height = 20
    const box_width = 20
    const box_h_gap = 5
    const box_v_gap = 5
    const word_gap = 10
    const padding = 10
    const line_height = 25
    const logo_width = 25
    const logo_height = logo_width
    const image_padding = 20
    canvas.width = (padding*2) + ((script[0]["adjective"].length + script[0]["noun"].length)*(box_width+box_h_gap)) + word_gap
    canvas.height = (padding*2) + title_height + title_padding + date_height + date_padding +(script.length*(box_height+box_v_gap)) + image_height + image_padding
    let ctx = canvas.getContext("2d")
    let ai_img_p = load_image(`/images/${today_string}.png`)
    let frame_img_p = load_image("/art_frame.png")
    let logo_image_p = load_image(logo)
    let ai_img = await ai_img_p
    let frame_img = await frame_img_p
    let logo_img = await logo_image_p
    let image_center_h = canvas.width/2
    let image_center_v = padding+title_height+title_padding+(image_height/2)
    ctx.fillStyle = "#282c34";
    ctx.fillRect(0,0, canvas.width, canvas.height)
    ctx.drawImage(logo_img, padding, padding, logo_width, logo_height)
    ctx.drawImage(ai_img, image_center_h-((image_width*ai_image_scale)/2), image_center_v-((image_height*ai_image_scale)/2), (image_width*ai_image_scale), (image_width*ai_image_scale))
    ctx.drawImage(frame_img, image_center_h-(image_width/2), padding+title_height+title_padding, image_width, image_height)
    ctx.fillStyle = "#ffffff"
    ctx.font = `${title_height}px sans-serif`
    ctx.textBaseline = "hanging"
    ctx.textAlign = "center"
    ctx.fillText(`${world.app_name}`, image_center_h, padding, canvas.width)

    ctx.fillStyle = "#ffffff";
    ctx.font = `${date_height}px sans-serif`;
    ctx.textBaseline = "hanging"
    ctx.textAlign = "center"
    ctx.fillText(`${today_string}`, image_center_h, padding+title_height+title_padding+image_height+image_padding, canvas.width)
    function draw_box(top, left, state) {
      ctx.font = `${box_height}px serif`
      ctx.textBaseline = "hanging"
      ctx.textAlign = "left"
      let text = BOARD_STATE_TEXT_MAP[state]
      ctx.fillText(text, left, top, 40)
    }
    const boxes_start = padding+title_height+title_padding+date_height+date_padding+image_height+image_padding
    for (let top=boxes_start, i=0; top < (boxes_start+(script.length*(box_height+box_v_gap))); top+=box_height+box_v_gap, i++) {
      let left = padding;
      let state = script[i]
      for (let letter of state["adjective"]) {
        draw_box(top, left, letter)
        left += box_height+box_h_gap
      }
      left += word_gap
      for (let letter of state["noun"]) {
        draw_box(top, left, letter)
        left += box_height+box_h_gap
      }
    }
    return canvas.toDataURL()
  }
  async function url_to_blob(url) {
    return await (await fetch(url)).blob(); 
  }
  const [shareType, setShareType] = useState(null)
  async function onShareStatsClick(event) {
      setShareType("stats")
      if (navigator.canShare && navigator.canShare({text: "Its text"})) {
        const stats_string = game_stats_to_string(statistics)
        const blob = await url_to_blob(await game_stats_to_image(statistics))
        const image_data = {
          files: [
            new File([blob], "MagicAIPuzzleStats.png", {
              type: blob.type
            }),
          ],
          title: "MagicAIPuzzleStats",
          text: stats_string,
        }
        if (!navigator.canShare(image_data)) {
          navigator.share({text: stats_string})
        } else {
          navigator.share(image_data)
        }
      } else {
        setShowShareTypeDialog(true)
      }
  }
  async function onShareGameClick(event) {
    setShareType("game")
    if (navigator.canShare && navigator.canShare({text: "Its text"})) {
        const game_script = get_game_script(guesses, lastSolve, adjective, noun, won, failed)
        const game_string = game_script_to_string(game_script)
        const blob = await url_to_blob(await game_script_to_image(game_script))
        const image_data = {
          files: [
            new File([blob], `MagicAIPuzzle${today_string}.png`, {
              type: blob.type
            }),
          ],
          title: `MagicAIPuzzle${today_string}.png`,
          text: game_string,
        }
        if (!navigator.canShare(image_data)) {
          navigator.share({text: game_string})
        } else {
          navigator.share(image_data)
        }
    } else {
      setShowShareTypeDialog(true)
    }
  }
  function onCopyToClipboardClick(event) {
    if (shareType === "game") {
      let game_script = get_game_script(guesses, lastSolve, adjective, noun, won, failed)
      let game_string = game_script_to_string(game_script)
      setShowShareTypeDialog(false)
      try {
        copy_to_clipboard(game_string, {format:"text/plain"})
        toast.success("Successfully set clipboard")
      } catch {
        toast.error("Failed to set clipboard")
      }
    }
    if (shareType === "stats") {
      const stats_string = game_stats_to_string(statistics)
      try{
        copy_to_clipboard(stats_string, {format:"text/plain"})
        toast.success("Successfully set clipboard")
      } catch {
        toast.error("Failed to set clipboard")
      }
    }
    setShowShareTypeDialog(false)
  }
  function onSaveToImageClick(event) {
    if (shareType === "game") {
      let game_script = get_game_script(guesses, lastSolve, adjective, noun, won, failed)
      let image_url_p = game_script_to_image(game_script)
      image_url_p.then((image_url)=>{
        saveAs(image_url,  `MagicAIPuzzle${today_string}.png`)
      })
    }
    if (shareType === "stats") {
      game_stats_to_image(statistics).then((url)=>{
        saveAs(url, "MagicAIPuzzleStats.png")
      })
    }
    setShowShareTypeDialog(false)
  }
  function onShowStatisticsClick(event) {
    setShowStatistics(true)
  }
  function onShowAboutClick(event) {
    setShowAbout(true)
  }
  function onStatisticsCloseClick(event) {
    setShowStatistics(false)
  }
  function onAboutCloseClick(event) {
    setShowAbout(false)
    store_local_value("about_dismissed", new Date(), setAboutDismissed)
  }
  function onShareTypeDialogCloseClick(event) {
    setShowShareTypeDialog(false)
  }
  function onPeekTomorrowClick(event) {
    setShowPeek(true)
  }
  function onPeekTomorrowCloseClick(event) {
    setShowPeek(false)
  }
  function onInstallPromptCloseClick(event) {
    setShowInstallPrompt(false)
    store_local_value("annoyed_to_install", new Date(), setAnnoyedToInstall)
  }
  function onInstallPromptInstallClick(event) {
    cachedInstallPrompt.prompt();
    cachedInstallPrompt.userChoice.then((choiceResult) => {
      setShowInstallPrompt(false)
      store_local_value("annoyed_to_install", new Date(), setAnnoyedToInstall)
    })
  }
  if (!doneOnce) {
    setDoneOnce(true)
  }
  let recent_solve = null;
  for (let guess of guesses) {
    if (guess.length > 1) {
      recent_solve = guess
    }
  }
  return (
    <div className="App theme--dark" onClick={onAppClick} onKeyUp={onKeyUp}>
      <header className="App-header">
        <div className="title-text">
        <span className="header-logo"></span><span>{world.app_name}</span></div>
        <div className="tools-holder">
          <div className="show-statistics icon-chart-bar" onClick={onShowStatisticsClick}/>
          <div className="show-about icon-help-circled" onClick={onShowAboutClick}/>
        </div>
      </header>
      <div className="App-body">
        <span className="art-holder"><img src={`/images/${today_string}.png`} className="art" /></span>
        <div className="hook-text">The AI is dreaming of...</div>
        <Puzzle adjective={adjective} noun={noun} guesses={guesses} overrides={overrides} override_states={BOARD_STATES} cursorLocation={cursorLocation} onClickInput={onInputClick} last_solve={recent_solve} dont_shake={((won||failed)&&!justEnded)||winOnLoad||showStatistics}/>
        {failed||won ? (<div className="answer-display"><span className="answer-display__adjective flip-in-hor-top">{adjective}</span> <span className="answer-display__noun flip-in-hor-top animation-delay--0_5">{noun}</span></div>) : ""}
        <div className="spacer"></div>
        {(!failed && !won) ? (<div className="guesses-remaining">{guesses_text}</div>) : ""}
        <Keyboard state_map={state_map} onKeyPressed={onKeyboardKeyPressed}/>
      </div>
      <StatisticsScreen show={showStatistics} show_install_app={cachedInstallPrompt !== null} onClose={onStatisticsCloseClick} onShareGameClick={onShareGameClick} onShareStatsClick={onShareStatsClick} onPeekTomorrowClick={onPeekTomorrowClick} onInstallAppClick={onInstallPromptInstallClick} statistics={statistics} guess_count={(won||failed) ? get_local_value("guesses").length : -1} failed={failed} won={won}/>
      <AboutScreen show={showAbout} onClose={onAboutCloseClick}/>
      <DataModal title="Share Type..." show={showShareTypeDialog} onClose={onShareTypeDialogCloseClick}>
        <div className="share-options-holder">
          <button onClick={onCopyToClipboardClick} className="share-options-holder__button button share-button share-text-button"><span className="icon-clipboard"/>Copy To Clipboard</button>
          <button onClick={onSaveToImageClick} className="share-options-holder__button button share-button share-image-button"><span className="icon-picture"/>Save Image</button>
        </div>
      </DataModal>
      <DataModal title="Tomorrow's Image" show={showPeek} onClose={onPeekTomorrowCloseClick}>
        <span className="art-holder"><img src={`/images/${tomorrow_string}.png`} className="art" /></span>
      </DataModal>
      <DataModal title="Install App?" show={showInstallPrompt && (cachedInstallPrompt !== null)} onClose={onInstallPromptCloseClick}>
        <div className="install-app__text">Enjoying {world.app_name}? Why not install to your home screen?</div>
        <div className="install-app__button-holder">
          <button className="install-app__button-holder__button install-button install-app__button-holder__button--install button" onClick={onInstallPromptInstallClick}><span class="icon-install"></span>Install</button>
          <button className="install-app__button-holder__button install-button install-app__button-holder__button--noinstall button" onClick={onInstallPromptCloseClick}>No Thanks</button>
        </div>
      </DataModal>
      <Toaster/>
    </div>
  );
}

export default App;
