import './keyboard.css';
import Key from "./key.jsx"
import Row from "./row.jsx"

function Keyboard({state_map, onKeyPressed}) {
	function _onKeyPressed({key}) {
		if (onKeyPressed) {
			return onKeyPressed({key})
		}
	}
	let rows = [["q","w","e","r","t","y","u","i","o","p"],
				["a","s","d","f","g","h","j","k","l"],
				["solve","z","x","c","v","b","n","m","<"]].map((row, index)=>{
					return (<Row letters={row} onKeyPressed={_onKeyPressed} state_map={state_map} key={index}></Row>)
				})

  	return (
    	<div className="Keyboard">
    		{rows}
    	</div>
  	);
}
Keyboard.Row = Row
Keyboard.Key = Key

export default Keyboard;
